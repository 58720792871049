import React, {useState} from 'react';
import Footer from '../home/Footer';
import NavigationBar from '../home/NavigationBar';
import diamond_img from './../../assets/imgs/hero_diamond.png';
import inventory from './../../assets/svgs/Huge_Inventory.svg';
import shipping from './../../assets/svgs/fastest_Shipping.svg';
import Quality from './../../assets/svgs/Quality_Check.svg';
import manage_order from './../../assets/svgs/manage_order.svg';
import lowest_pricing from './../../assets/svgs/lowest_pricing.svg';
import Customer_Support from './../../assets/svgs/Customer_Support.svg';
import Accordion from 'react-bootstrap/Accordion';
import {Helmet} from 'react-helmet';
import {t} from 'i18next';

function ForBuyers() {
  const [activePanel, setActivePanel] = useState(null);
  const togglePanel = (panel) => {
    setActivePanel(activePanel === panel ? null : panel);
  };

  const accordionItems = [
    {
      eventKey: '0',
      header: t('landingpage.faq1'),
      body: t('landingpage.faq2'),
    },
    {
      eventKey: '1',
      header: t('landingpage.faq3'),
      body: t('landingpage.faq4'),
    },
    {
      eventKey: '2',
      header: t('landingpage.faq5'),
      body: t('landingpage.faq6'),
    },
    {
      eventKey: '3',
      header: t('landingpage.faq7'),
      body: t('landingpage.faq8'),
    },
    {
      eventKey: '4',
      header: t('landingpage.faq9'),
      body: t('landingpage.faq10'),
    },
    {
      eventKey: '5',
      header: t('landingpage.faq11'),
      body: t('landingpage.faq12'),
    },
    {
      eventKey: '6',
      header: t('landingpage.faq13'),
      body: t('landingpage.faq14'),
    },
  ];

  const salientFeatures = [
    {
      title: t('landingpage.buy4'),
      description: t('landingpage.buy5'),
      imageSrc: inventory,
      reverse: false,
    },
    {
      title: t('landingpage.buy6'),
      description: t('landingpage.buy7'),
      imageSrc: shipping,
      reverse: true,
    },
    {
      title: t('landingpage.buy8'),
      description: t('landingpage.buy9'),
      imageSrc: Quality,
      reverse: false,
    },
    {
      title: t('landingpage.buy10'),
      description: t('landingpage.buy11'),
      imageSrc: manage_order,
      reverse: true,
    },
    {
      title: t('landingpage.buy12'),
      description: t('landingpage.buy13'),
      imageSrc: lowest_pricing,
      reverse: false,
    },
    {
      title: t('landingpage.buy14'),
      description: t('landingpage.buy15'),
      imageSrc: Customer_Support,
      reverse: true,
    },
  ];
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

  return (
    <>
      <NavigationBar />

      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta charSet="en-US" />
        <title>Buy Best Diamonds Online - Transectra</title>
        <meta
          name="description"
          content="Shop the finest diamonds online at Transectra. Find quality diamonds, competitive prices, and a secure buying experience for every style and budget"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            url: 'https://www.transectra.com/for-buyers',
            name: 'For Buyer',
            description:
              'Shop the finest diamonds online at Transectra. Find quality diamonds, competitive prices, and a secure buying experience for every style and budget.',
          })}
        </script>
      </Helmet>

      <div className="tr__for_buyer_bg_main">
        <div className="container">
          <div className="tr__for_buyer_bg_content">
            <div className="tr__for_buyer_content_wrapper">
              <h1>{t('landingpage.buy1')}</h1>
              <p>{t('landingpage.buy2')}</p>
            </div>
            <div className="tr__diamond_img_wrapper">
              <img src={diamond_img} alt="img" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="tr_sailent_heading">
          <h2 className="text-center">{t('landingpage.buy3')}</h2>
          <div className="border-content"></div>
        </div>
        {salientFeatures.map((feature, index) => (
          <div
            className="tr_salient_features_container row align-items-center justify-content-center"
            key={index}>
            <div
              className={`tr__silent_img_wrapper col-12 col-sm-6 ${
                feature.reverse ? 'order-1 order-md-2' : ''
              }`}>
              <img src={feature.imageSrc} alt="img" />
            </div>
            <div
              className={`tr_salient_features_content_holder col-12 col-sm-6 ${
                feature.reverse ? 'order-2 order-md-1' : ''
              }`}>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="container">
        <div className="tr_faq">
          <h2 className="text-center">{t('landingpage.faq')}</h2>
          <div className="border-content"></div>
        </div>
        <Accordion defaultActiveKey="0" className="tr__buyer_acc">
          {accordionItems.map((item) => (
            <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
              <Accordion.Header>{item.header}</Accordion.Header>
              <Accordion.Body>
                <p>{item.body}</p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
      <Footer />
    </>
  );
}

export default ForBuyers;
