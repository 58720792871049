import React, {useContext, useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../assets/svgs/logo1.svg';
import constants from '../../utils/constants';
import SvgsComponent from '../../components/SvgsComponent';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import i18n from '../../i18n/i18n';
import {LoaderContext} from '../../App';

function NavigationBar() {
  const currentLocation = useLocation();
  let user = localStorage.getItem(constants.USER);
  let jsonValue = JSON.parse(user);
  const {t} = useTranslation();
  // Use a default flag if the language is not in the mapping
  const language = localStorage.getItem('selectedLanguage');
  const {setIsLoading} = useContext(LoaderContext);

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const navLinks = [
    {href: '/about-us', label: t('landingpage.About_Us')},
    {href: '/for-buyers', label: t('landingpage.For_Buyer')},
    {href: '/for-supplier', label: t('landingpage.For_Supplier')},
    {href: '/contact-us', label: t('landingpage.Contact_Us')},
  ];

  const languageIcons = {
    en: 'flag',
    zh: 'china',
    rs: 'russia',
    ar: 'arabic',
    hi: 'india',
    fr: 'french',
    sp: 'spanish',
    it: 'italian',
    ge: 'german',
    ja: 'japanese',
    tu: 'turkish',
    ko: 'korean',
    pe: 'persian',
    pr: 'portuguese',
  };

  // useEffect(() => {
  //   if (language) {
  //     changeLanguage(language);
  //   } else {
  //     changeLanguage('en');
  //   }
  // }, []);

  const changeLanguage = (lng) => {
    localStorage.setItem('selectedLanguage', lng);
    i18n.changeLanguage(lng);
    console.log('object');
    setIsLoading(true);
    setTimeout(() => {
      window.location.reload(); // Reload page
      setIsLoading(false);
    }, 1000);
  };

  const selectedLanguageIcon = languageIcons[language] || 'flag';

  return (
    <>
      <Navbar expand="lg" className="navbar_web" expanded={expanded}>
        <Container>
          <Navbar.Brand href="/" className="tr__logo" aria-label="Home">
            {/* <img src={logo} alt="Logo" /> */}
            <img src={logo} alt="Logo" style={{height: 66, width: 155}} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" onClick={handleToggle}>
            {expanded ? (
              <SvgsComponent name="close" alt="close" />
            ) : (
              <SvgsComponent name="menuIcon" alt="menuIcon" />
            )}
          </Navbar.Toggle>
          <Navbar.Collapse className="tr__navbar" id="navbarScroll">
            <Nav className="my-2 my-lg-0 tr__menulist">
              {navLinks.map((link) => (
                <Nav.Link
                  key={link.href}
                  href={link.href}
                  className={
                    currentLocation.pathname === link.href ? 'active' : ''
                  }>
                  {link.label}
                </Nav.Link>
              ))}
            </Nav>
            {jsonValue !== null && jsonValue !== undefined ? (
              <Nav className="my-2 my-lg-0 tr__btnlist">
                <Nav.Link href="/dashboard" className="login_btn fill_btn">
                  {t('landingpage.Go_to_Dashboard')}
                </Nav.Link>
              </Nav>
            ) : (
              <Nav className="my-2 my-lg-0 tr__btnlist">
                <Nav.Link href="/login" className="login_btn fill_btn">
                  {t('landingpage.Login')}
                </Nav.Link>
                <Nav.Link href="/register" className="createAc_btn outline_btn">
                  {t('landingpage.Create_an_account')}
                </Nav.Link>

                <Dropdown className="profile">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="con_drop top_drop">
                    <SvgsComponent
                      name={selectedLanguageIcon}
                      alt={selectedLanguageIcon}
                    />

                    {/* <SvgsComponent name="flag" alt="flag" /> */}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="top_dropmenu">
                    <Dropdown.Item
                      className="con_drop"
                      onClick={() => changeLanguage('en')}>
                      <SvgsComponent name="flag" alt="flag" />
                      {/* <span>English</span> */}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="con_drop"
                      onClick={() => changeLanguage('zh')}>
                      <SvgsComponent name="china" alt="china" />
                      {/* <span>Chinese</span> */}
                    </Dropdown.Item>

                    <Dropdown.Item
                      onClick={() => changeLanguage('rs')}
                      className="con_drop">
                      <SvgsComponent name="russia" alt="russia" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage('ar')}
                      className="con_drop">
                      <SvgsComponent name="arabic" alt="arabic" />
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={() => changeLanguage('hi')}
                      className="con_drop">
                      <SvgsComponent name="india" alt="india" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage('fr')}
                      className="con_drop">
                      <SvgsComponent name="french" alt="french" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage('sp')}
                      className="con_drop">
                      <SvgsComponent name="spanish" alt="spanish" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage('it')}
                      className="con_drop">
                      <SvgsComponent name="china" alt="china" />
                    </Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={() => changeLanguage('ge')}
                      className="con_drop">
                      <SvgsComponent name="german" alt="german" />
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                      onClick={() => changeLanguage('ja')}
                      className="con_drop">
                      <SvgsComponent name="japanese" alt="japanese" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage('tu')}
                      className="con_drop">
                      <SvgsComponent name="turkish" alt="turkish" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage('ko')}
                      className="con_drop">
                      <SvgsComponent name="korean" alt="korean" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage('pe')}
                      className="con_drop">
                      <SvgsComponent name="persian" alt="persian" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => changeLanguage('pr')}
                      className="con_drop">
                      <SvgsComponent name="portuguese" alt="portuguese" />
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;
