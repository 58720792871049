import React, {Suspense, createContext, lazy, useEffect, useState} from 'react';
import '../src/App.css';
import {SearchStockProvider} from './context/SearchStockContext';
import {Navigate, Route, Routes} from 'react-router-dom';
import toast, {Toaster} from 'react-hot-toast';
import Loader from './components/load/Loader';
import {DataProvider} from './context/PriceList';
import ForBuyers from './pages/forbuyers/ForBuyers';
import ContactUs from '../src/pages/contactUs/ContactUs';
import ForSupplier from './pages/forSeller/ForSupplier';
import AboutUs from './pages/aboutUs/AboutUs';
import {app} from './firebase/firebase';
import {getMessaging, onMessage} from 'firebase/messaging';
import useFcmToken from './context/useFcmToken';
import ChatPage from './components/chatPage/ChatPage';
import i18n from './i18n/i18n';

const CertificateDow = lazy(() =>
  import('./components/dashboard/CertificateDow'),
);

const LandingPage = lazy(() => import('./pages/home/LandingPage'));
const TermsAndCondition = lazy(() => import('./pages/terms/TermsAndCondition'));
const PrivacyPolicy = lazy(() => import('./pages/terms/PrivacyPolicy'));
const LicenseAgreement = lazy(() => import('./pages/terms/LicenseAgreement'));
const CodeofConduct = lazy(() => import('./pages/terms/CodeofConduct'));
const HoldList = lazy(() => import('./components/holdList/HoldList'));

const SavedCalculation = lazy(() =>
  import('./pages/savedCalculation/SavedCalculation'),
);
const Login = lazy(() => import('./pages/login/Login'));
const Register = lazy(() => import('./pages/register/Register'));
const ForgotPassword = lazy(() =>
  import('./pages/forgotpassword/ForgotPassword'),
);
const ResetPassword = lazy(() => import('./pages/reset/ResetPassword'));
const PricingPlan = lazy(() => import('./pages/pricingPlans/PricingPlan'));
const ResultsPage = lazy(() => import('./pages/resultspage/ResultsPage'));
const MessageBox = lazy(() => import('./components/messagebox/MessageBox'));
const MainWrapper = lazy(() => import('./components/mainwrapper/MainWrapper'));
const Home = lazy(() => import('./pages/dashboard/Home'));
const TradeCenter = lazy(() => import('./pages/tradeCenter/TradeCenter'));
const CalculatorPage = lazy(() =>
  import('./components/calculator/CalculatorPage'),
);
const SearchStock = lazy(() => import('./pages/searchStock/SearchStock'));
const Support = lazy(() => import('./components/support/Support'));
const Profile = lazy(() => import('./components/profile/Profile'));
const CompareDimond = lazy(() =>
  import('./pages/compareDiamond/CompareDiamond'),
);
const LostConnection = lazy(() =>
  import('./components/lostConnection/LostConnection'),
);
const BuyRequest = lazy(() => import('./pages/buyRequest/BuyRequest'));
const SaveSearch = lazy(() => import('./components/saveSearches/SaveSearch'));
const StoneDetails = lazy(() => import('./pages/stoneDetails/StoneDetails'));
const RecentSearch = lazy(() => import('./pages/recentSearch/RecentSearch'));
const TrackedDiamond = lazy(() =>
  import('./components/trackDiamond/TrackedDiamond'),
);
const UploadDiamond = lazy(() =>
  import('./components/uploadDiamond/UploadDiamond'),
);
const MyCart = lazy(() => import('./pages/myCart/MyCart'));
const NotificationCenter = lazy(() =>
  import('./pages/notificationsCenter/NotificationsCenter'),
);
const RapaPortPrice = lazy(() => import('./pages/rapaportPrice/RapaportPrice'));
const WatchList = lazy(() => import('./pages/watchList/WatchList'));
const RegionalOffices = lazy(() =>
  import('./pages/regionalOffices/RegionalOffices'),
);
const TermsAndCon = lazy(() =>
  import('./components/termsAndCondition/TermsAndCon'),
);
const SettingsAndPref = lazy(() =>
  import('./components/settingAndPrefferences/SettingsAndPref'),
);
const AnnouncementPage = lazy(() =>
  import('./pages/announcement/Announcement'),
);

const PasswordSecurity = lazy(() =>
  import('./components/passwordSecurity/PasswordSecurity'),
);
const Permission = lazy(() => import('./components/permission/Permission'));
const PlansAndHistory = lazy(() =>
  import('./components/plansHistory/PlansAndPuchase'),
);
const PlansAndPurchase = lazy(() =>
  import('./components/plansHistory/PlansAndPuchase'),
);
const TrackedDiamondList = lazy(() =>
  import('./components/trackDiamond/TrackedDiamondList'),
);
const OrderList = lazy(() => import('./pages/orderList/OrderList'));
const RapSpecs = lazy(() => import('./components/rapspecs/RapSpecs'));
const AddressSelection = lazy(() =>
  import('./pages/addressSelection/AddressSelection'),
);
const Checkout = lazy(() => import('./pages/checkoutPage/Checkout'));
const EditDiamond = lazy(() =>
  import('./components/uploadDiamond/EditDiamond'),
);
const PairDiamond = lazy(() => import('./components/pairDiamond/PairDiamond'));
const MultipaldimondList = lazy(() =>
  import('./components/uploadDiamond/MultipaldimondList'),
);
const ManageMyDiamond = lazy(() =>
  import('./pages/manageMyDiamond/ManageMyDiamond'),
);

const ShareDiamonf = lazy(() => import('./pages/shareDiamondtem/ShareDiamond'));

export const LoaderContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
});

function App() {
  const [isLoading, setIsLoading] = useState(false);

  const {fcmToken, notificationPermissionStatus} = useFcmToken();
  // Use the token as needed
  // fcmToken && console.log('FCM token:', fcmToken);

  // eslint-disable-next-line react-hooks/rules-of-hooks

  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const messaging = getMessaging(app);
      const unsubscribe = onMessage(messaging, (payload) => {
        const title = payload?.notification?.title;
        const body = payload?.notification?.body;

        toast.success(
          <div>
            {title}
            <br />
            {body}
          </div>,
          {
            position: 'top-right',
            style: {width: '300px', height: '100px'},
          },
        );
        // Handle the received push notification while the app is in the foreground
        // You can display a notification or update the UI based on the payload
      });
      return () => {
        unsubscribe(); // Unsubscribe from the onMessage event
      };
    }
  }, []);

  useEffect(() => {
    const language = navigator.language || navigator.userLanguage;
    const country = language.split('-')[1] || language.split('_')[1];
  }, []);

  return (
    <LoaderContext.Provider value={{isLoading, setIsLoading}}>
      {isLoading && <Loader />}
      <Toaster position="top-right" />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/message" element={<MessageBox />} />
          <Route path="/pricing-plans" element={<PricingPlan />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms-condition" element={<TermsAndCondition />} />
          <Route path="/codeofconduct" element={<CodeofConduct />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/for-buyers" element={<ForBuyers />} />
          <Route path="/for-supplier" element={<ForSupplier />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/license-agreement" element={<LicenseAgreement />} />
          <Route path="/share-diamond/:id" element={<ShareDiamonf />} />

          <Route
            path="/dashboard"
            element={
              <MainWrapper>
                <DataProvider>
                  <Home />
                </DataProvider>
              </MainWrapper>
            }
          />
          <Route
            path="/trade-center"
            element={
              <MainWrapper>
                <TradeCenter />
              </MainWrapper>
            }
          />
          <Route
            path="/certificate-dow"
            element={
              <MainWrapper>
                <CertificateDow />
              </MainWrapper>
            }
          />
          <Route
            path="/support"
            element={
              <MainWrapper>
                <Support />
              </MainWrapper>
            }
          />
          <Route
            path="/profile"
            element={
              <MainWrapper>
                <Profile />
              </MainWrapper>
            }
          />
          <Route
            path="/compare-diamond"
            element={
              <MainWrapper>
                <CompareDimond />
              </MainWrapper>
            }
          />
          <Route
            path="/lost-connection"
            element={
              <MainWrapper>
                <LostConnection />
              </MainWrapper>
            }
          />
          <Route
            path="/settings-prefferences"
            element={
              <MainWrapper>
                <SettingsAndPref />
              </MainWrapper>
            }
          />
          <Route
            path="/buy-request"
            element={
              <MainWrapper>
                <SearchStockProvider>
                  <BuyRequest />
                </SearchStockProvider>
              </MainWrapper>
            }
          />
          <Route
            path="/order-list"
            element={
              <MainWrapper>
                <OrderList />
              </MainWrapper>
            }
          />
          <Route
            path="/save-search"
            element={
              <MainWrapper>
                <SaveSearch />
              </MainWrapper>
            }
          />
          <Route
            path="/recent-search"
            element={
              <MainWrapper>
                <RecentSearch />
              </MainWrapper>
            }
          />
          <Route
            path="/track-diamond"
            element={
              <MainWrapper>
                <TrackedDiamondList />
              </MainWrapper>
            }
          />
          <Route
            path="/rapaport-price"
            element={
              <MainWrapper>
                <RapaPortPrice />
              </MainWrapper>
            }
          />
          <Route
            path="/permission"
            element={
              <MainWrapper>
                <Permission />
              </MainWrapper>
            }
          />
          <Route
            path="/plans-purchase"
            element={
              <MainWrapper>
                <PlansAndPurchase />
              </MainWrapper>
            }
          />
          <Route
            path="/password-security"
            element={
              <MainWrapper>
                <PasswordSecurity />
              </MainWrapper>
            }
          />
          <Route
            path="/upload-diamond"
            element={
              <MainWrapper>
                <DataProvider>
                  <UploadDiamond />
                </DataProvider>
              </MainWrapper>
            }
          />
          <Route
            path="/notification-center"
            element={
              <MainWrapper>
                <NotificationCenter />
              </MainWrapper>
            }
          />
          <Route
            path="/results"
            element={
              <MainWrapper>
                <ResultsPage />
              </MainWrapper>
            }
          />
          <Route
            path="/calculator"
            element={
              <MainWrapper>
                <CalculatorPage />
              </MainWrapper>
            }
          />
          <Route
            path="/search-stock"
            element={
              <MainWrapper>
                <SearchStockProvider>
                  <SearchStock />
                </SearchStockProvider>
              </MainWrapper>
            }
          />
          <Route
            path="/new-uploads"
            element={
              <MainWrapper>
                <ResultsPage isNewUpload />
              </MainWrapper>
            }
          />
          <Route
            path="/diamond/:id"
            element={
              <MainWrapper>
                <StoneDetails />
              </MainWrapper>
            }
          />
          <Route
            path="/pair-search"
            element={
              <MainWrapper>
                <SearchStockProvider>
                  <SearchStock page="pair" />
                </SearchStockProvider>
              </MainWrapper>
            }
          />
          <Route
            path="/pair-search-results"
            element={
              <MainWrapper>
                <PairDiamond />
              </MainWrapper>
            }
          />
          <Route
            path="/mycart"
            element={
              <MainWrapper>
                <MyCart />
              </MainWrapper>
            }
          />
          <Route
            path="/watch-list"
            element={
              <MainWrapper>
                <WatchList />
              </MainWrapper>
            }
          />
          <Route
            path="/regional-offices"
            element={
              <MainWrapper>
                <RegionalOffices />
              </MainWrapper>
            }
          />
          <Route
            path="/chat-page"
            element={
              <MainWrapper>
                <ChatPage />
              </MainWrapper>
            }
          />

          {/* content pages */}
          <Route path="/content/:name" element={<TermsAndCon />} />
          <Route path="/terms-condition" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/license-agreement" element={<LicenseAgreement />} />
          <Route path="/code-of-conduct" element={<CodeofConduct />} />
          <Route
            path="/rapspecs"
            element={<RapSpecs activeTab={'TranSpecs'} />}
          />
          <Route
            path="/Address-selection"
            element={
              <MainWrapper>
                <AddressSelection />
              </MainWrapper>
            }
          />

          <Route
            path="/checkout"
            element={
              <MainWrapper>
                <Checkout />
              </MainWrapper>
            }
          />
          <Route
            path="/saved-calculation"
            element={
              <MainWrapper>
                <SavedCalculation />
              </MainWrapper>
            }
          />

          <Route
            path="/hold-list"
            element={
              <MainWrapper>
                <HoldList />
              </MainWrapper>
            }
          />

          <Route
            path="/edit-diamond/:id"
            element={
              <MainWrapper>
                <EditDiamond />
              </MainWrapper>
            }
          />

          <Route
            path="/announcement"
            element={
              <MainWrapper>
                <AnnouncementPage />
              </MainWrapper>
            }
          />

          <Route
            path="/diamondlist"
            element={
              <MainWrapper>
                <MultipaldimondList />
              </MainWrapper>
            }
          />

          <Route
            path="/managemydiamond"
            element={
              <MainWrapper>
                <DataProvider>
                  <ManageMyDiamond />
                </DataProvider>
              </MainWrapper>
            }
          />

          <Route path="*" element={<Navigate to={'/lost-connection'} />} />
        </Routes>
      </Suspense>
    </LoaderContext.Provider>
  );
}

export default App;
