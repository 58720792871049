import React, {useContext} from 'react';
import Footer from '../home/Footer';
import NavigationBar from '../home/NavigationBar';
import contact_us from './../../assets/imgs/contact-us_form.webp';
import user_icon from './../../assets/svgs/user-form.svg';
import email_icon from './../../assets/svgs/mail_form.svg';
import call_icon from './../../assets/svgs/call_form.svg';
import contact_city1 from './../../assets/svgs/contact_australia.svg';
import contact_city2 from './../../assets/svgs/contact_london.svg';
import contact_city3 from './../../assets/svgs/contact_hong_kong.svg';
import {LoaderContext} from '../../App';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {getUser} from '../../utils/constants';
import {fetchDataFromAPI} from '../../utils/api';
import toast from 'react-hot-toast';
import {Helmet} from 'react-helmet';

function ContactUs() {
  const {setIsLoading, isLoading} = useContext(LoaderContext);
  const {t} = useTranslation();

  const contactCards = [
    {
      imgSrc: contact_city1,
      address: t('landingpage.address'),
      phone: '+918889777721',
    },
    // {
    //   imgSrc: contact_city2,
    //   address:
    //     "Level 30, The Leadenhall Building, 122 Leadenhall Street, London EC3V 4AB",
    //   phone: "+44 (0)781 0086 078",
    // },
    // {
    //   imgSrc: contact_city3,
    //   address: "Chevalier House, 45-51 Chatham, Tsim Sha Tsui, Hong Kong",
    //   phone: "+91 99247 02227",
    // },
  ];

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      number: '',
      message: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('Please Enter Your Firstname'),
      lastName: Yup.string().required('Please Enter Your Lastname'),
      email: Yup.string()
        .email('You have entered an invalid Email')
        .required('Please Enter Your Email'),
    }),

    onSubmit: (values) => {
      const {firstName, lastName, email, number, message} = values;

      const body = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: number,
        message: message,
      };

      setIsLoading(true);
      fetchDataFromAPI(`ContactUs`, 'post', body, '')
        .then((res) => {
          toast.success(res?.message);
          // getAddresses();
          // handleClose();
          formik.resetForm();
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err?.response?.data?.message);
          setIsLoading(false);
        });
    },
  });
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

  return (
    <>
      <NavigationBar />
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta charSet="en-US" />

        <title>Contact Transectra - We're Here to Help You</title>

        <meta
          name="description"
          content="Reach out to Transectra for assistance with our diamond marketplace. Our support team is ready to answer questions and help you connect with trusted suppliers"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'ContactPage',
            url: 'https://www.transectra.com/contact-us',
            mainEntity: {
              '@type': 'Organization',
              name: 'Transectra',
              url: 'https://www.transectra.com',
              logo: 'https://www.transectra.com/static/media/logo1.ea5d6d74a502e450278bbd239bb726f8.svg',
              contactPoint: {
                '@type': 'ContactPoint',
                telephone: '+918889777721',
                contactType: 'Customer Service',
                areaServed: 'Worldwide',
                availableLanguage: ['English', 'Hindi'],
              },
              address: {
                '@type': 'PostalAddress',
                streetAddress: '6/2595, Plot No.4, Swaminarayan Wadi',
                addressLocality: 'Raghunathpura, Surat',
                addressRegion: 'Gujarat',
                postalCode: '395003',
                addressCountry: 'India',
              },
              sameAs: [
                'https://www.facebook.com/people/Transectra/61564622960129/?mibextid=LQQJ4d',
              ],
            },
          })}
        </script>
      </Helmet>
      <div className="tr__contact_us_bg">
        <div className="tr__contactus_heading">
          <div className="border-content"></div>
          <h1>{t('landingpage.contact1')}</h1>
        </div>
        {/* <p>Need a help? We are here to help you!</p> */}
      </div>
      {/* ---------------- */}
      <div className="tr__form_wrapper_main">
        <div className="tr__about_us_form_img_wrapper">
          <img src={contact_us} alt="img" />
        </div>
        <div className="tr__width_custome">
          <div className="tr__contact_heading">
            <h2>{t('landingpage.contact2')}</h2>
            <p>{t('landingpage.contact3')}</p>
            <div className="border-content"></div>
          </div>
          {/* form */}
          <div className="tr__form-container">
            <form id="contact-form">
              <div className="tr__input_wrapper">
                <div className="tr__form-group">
                  <label htmlFor="first-name">
                    {t('landingpage.contact4')}
                  </label>
                  <div className="tr__input_main_wrapper">
                    <div className="tr__user_icon_img_main">
                      <img src={user_icon} alt="img" />
                    </div>
                    <input
                      type="text"
                      id="first-name"
                      name="first-name"
                      value={formik.values.firstName}
                      // required
                      placeholder={t('landingpage.contact5')}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'firstName',
                          e.target.value.trimStart(),
                        )
                      }
                    />
                  </div>
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="err_msg">{formik.errors.firstName} </div>
                  )}
                </div>
                <div className="tr__form-group">
                  <label htmlFor="first-name">
                    {t('landingpage.contact6')}
                  </label>
                  <div className="tr__input_main_wrapper">
                    <div className="tr__user_icon_img_main">
                      <img src={user_icon} alt="img" />
                    </div>
                    <input
                      type="text"
                      id="last-name"
                      name="last-name"
                      // required
                      value={formik.values.lastName}
                      placeholder={t('landingpage.contact7')}
                      onChange={(e) =>
                        formik.setFieldValue(
                          'lastName',
                          e.target.value.trimStart(),
                        )
                      }
                    />
                  </div>
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="err_msg">{formik.errors.lastName} </div>
                  )}
                </div>
                {/* <div className="tr__form-group">
                                    <label htmlFor="last-name">Last Name</label>
                                    <input type="text" id="last-name" name="last-name" required placeholder='Enter your last name...' />
                                </div> */}
              </div>
              <div className="tr__form-group">
                <label htmlFor="email">{t('landingpage.contact8')}</label>
                <div className="tr__input_main_wrapper">
                  <div className="tr__user_icon_img_main">
                    <img src={email_icon} alt="img" />
                  </div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    // required
                    value={formik.values.email}
                    placeholder={t('landingpage.contact9')}
                    onChange={(e) =>
                      formik.setFieldValue('email', e.target.value.trimStart())
                    }
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <div className="err_msg">{formik.errors.email} </div>
                )}
              </div>
              <div className="tr__form-group">
                <label htmlFor="phone">{t('landingpage.contact10')}</label>
                <div className="tr__input_main_wrapper">
                  <div className="tr__user_icon_img_main">
                    <img src={call_icon} alt="img" />
                  </div>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formik.values.number}
                    // required
                    placeholder="+91 00000 00000"
                    onChange={(e) =>
                      formik.setFieldValue('number', e.target.value.trimStart())
                    }
                  />
                </div>
              </div>
              <div className="tr__form-group">
                <label htmlFor="message">{t('landingpage.contact11')}</label>
                <div className="tr__input_main_wrapper_area">
                  {/* <div className='tr__user_icon_img_main'>
                                        <img src={call_icon} alt="img" />
                                    </div> */}
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    // required
                    value={formik.values.message}
                    placeholder={t('landingpage.contact12')}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'message',
                        e.target.value.trimStart(),
                      )
                    }></textarea>
                </div>
              </div>
              <button
                type="submit"
                className="submit-btn"
                onClick={formik.handleSubmit}>
                {t('landingpage.contact13')} <span className="arrow">→</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      {/*  */}
      <div className="container">
        <div className="tr__contact_info_heading d-flex flex-column align-items-center justify-content-center max-w-max">
          <h2>{t('landingpage.contact14')}</h2>
          <div className="border-content"></div>
        </div>
        {/* cards design */}
        <div className="tr__grid_contact_info">
          {contactCards.map((card, index) => (
            <div className="tr__grid_contact_card_contaier" key={index}>
              <div className="tr__bg_contact_us">
                <div className="tr__contact_us_image_container">
                  <img src={card.imgSrc} alt="img" />
                </div>
              </div>
              <div className="tr__grid_contact_card_content">
                <h5>{card.address}</h5>
                <a href={`tel:${card.phone}`}>{card.phone}</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* End: About us */}
      <Footer />
    </>
  );
}

export default ContactUs;
