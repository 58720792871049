import React from 'react';
import Footer from '../home/Footer';
import NavigationBar from './../home/NavigationBar';
import diamond_bg_img from './../../assets/imgs/about_heading.jpg';
import card_one from './../../assets/svgs/card_one.svg';
import card_two from './../../assets/svgs/card_two.svg';
import card_three from './../../assets/svgs/card_three.svg';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

function AboutUs() {
  const {t} = useTranslation();
  const cards = [
    {
      title: t('landingpage.about9'),
      description: t('landingpage.about10'),
      imageSrc: card_one,
    },
    {
      title: t('landingpage.about11'),
      description: t('landingpage.about12'),
      imageSrc: card_two,
    },
    {
      title: t('landingpage.about13'),
      description: t('landingpage.about14'),
      imageSrc: card_three,
    },
  ];

  const aboutUsCounts = [
    {
      number: '01',
      title: t('landingpage.about4_1'),
      content: t('landingpage.about4_2'),
    },
    {
      number: '02',
      title: t('landingpage.about5_1'),
      content: t('landingpage.about5_2'),
    },
    {
      number: '03',
      title: t('landingpage.about6_1'),
      content: t('landingpage.about6_2'),
    },
    {
      number: '04',
      title: t('landingpage.about7_1'),
      content: t('landingpage.about7_2'),
    },
  ];
  const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
  return (
    <>
      <NavigationBar />

      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <meta charSet="en-US" />

        <title>Buy & Sell Best Quality Diamonds Online - Transectra</title>
        <meta
          name="description"
          content="Discover premium diamonds on Transectra's online marketplace. Buy and sell top-quality diamonds with trusted sellers and secure transactions worldwide"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'AboutPage',
            url: 'https://www.transectra.com/about-us',
            mainEntity: {
              '@type': 'Organization',
              name: 'Transectra',
              url: 'https://www.transectra.com',
              logo: 'https://www.transectra.com/static/media/logo1.ea5d6d74a502e450278bbd239bb726f8.svg',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '6/2595, Plot No.4, Swaminarayan Wadi',
                addressLocality: 'Raghunathpura, Surat',
                addressRegion: 'Gujarat',
                postalCode: '395003',
                addressCountry: 'India',
              },
              sameAs: [
                'https://www.facebook.com/people/Transectra/61564622960129/?mibextid=LQQJ4d',
              ],
            },
          })}
        </script>
      </Helmet>
      {/* Start: About us */}
      {/* <div className='tr__aboutus-bg'>
                <div className='container'>
                    <div className='tr__about_us_main'>
                        <div className='tr__aboutus_heading'>
                            <div className='border-content'></div>
                            <h4>About Us</h4>
                        </div>
                        <h2>Best place to get a diamonds</h2>
                        <p>With perseverance, dedication, and expertise, The Transectra is on the path to becoming one of the largest B2B online channels for listing certified diamonds. We work round the clock to offer a trusted marketspace for the jewelry industry where we build professional connections between purchasers and suppliers.</p>
                        <p>With our B2B marketplace for diamonds to serve clients irrespective of their business size or location, expand their profits, and open new doors of success. We have a huge portfolio of natural diamonds and lab-grown diamonds from trusted suppliers.</p>
                    </div>
                </div>
            </div> */}
      <div className="tr__aboutus-bg">
        <div className="tr__aboutus_heading">
          <div className="border-content"></div>
          <h1> {t('landingpage.About_Us')}</h1>
        </div>
        <div className="container">
          <div className=" flex-md-column flex-column flex-lg-row d-flex align-items-center gap-aboutus">
            <div className="tr__aboutus_img_container">
              <img src={diamond_bg_img} alt="img" />
            </div>
            <div className="tr__about_us_main">
              <div className="tr__aboutus_subheading">
                <div className="border-content"></div>
                <h2>{t('landingpage.about1')}</h2>
              </div>
              <p>{t('landingpage.about2')}</p>
              <p>{t('landingpage.about3')}</p>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------- */}
      <div className="container">
        <div className="tr__counts_heading_port">
          <h2>{t('landingpage.about15')}</h2>
          <div className="border-content"></div>
        </div>
        {aboutUsCounts.map((item, index) => (
          <div className="tr_aboutus_counts_main" key={index}>
            <div className="tr_aboutus_count_h1">
              <h2>{item.number}</h2>
            </div>
            <div className="tr_aboutus_count_content">
              <h5>{item.title}</h5>
              <p>{item.content}</p>
            </div>
          </div>
        ))}
      </div>
      {/*  */}
      <div className="container">
        <div className="tr__counts_heading_port d-flex flex-column align-items-center justify-content-center max-w-max">
          <h2>{t('landingpage.about8')}</h2>
          <div className="border-content"></div>
        </div>
        {/* cards design */}
        <div className="row px-3 px-md-0 tr__gaping_add justify-content-md-center justify-content-lg-start tr_extra_b_margin">
          {cards.map((card, index) => (
            <div className="card_wrapper_main col-lg col-md-8" key={index}>
              <div className="card_img_wrapper">
                <img src={card.imageSrc} alt="img" />
              </div>
              <h5>{card.title}</h5>
              <p>{card.description}</p>
            </div>
          ))}
        </div>
      </div>
      {/* End: About us */}
      <Footer />
    </>
  );
}

export default AboutUs;
