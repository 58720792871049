import React, {useEffect, useRef, useState} from 'react';
import {getUser} from '../../utils/constants';
import {useNavigate} from 'react-router-dom';
import {fetchDataFromAPI} from '../../utils/api';
import {io} from 'socket.io-client';
import Loader from '../load/Loader';
import moment from 'moment/moment';
import SvgsComponent from '../SvgsComponent';

const ChatPage = () => {
  const userData = JSON.parse(getUser());
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(0);
  const chatContainerRef = useRef(null);
  const messagesEndRef = useRef(null);
  const socket = io('https://preprod.transectra.com:8443');

  const getUsers = (page) => {
    setIsLoading(true);
    fetchDataFromAPI(
      `chat/getchathistory?userId=${userData?._id}&page=${page}`,
      'get',
      '',
      userData?.token,
    )
      .then((res) => {
        setIsLoading(false);
        setMessages((prevMessages) => [
          ...res?.data?.reverse(),
          ...prevMessages,
        ]);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error', error);
      });
  };

  useEffect(() => {
    getUsers(page); // Fetch initial messages
  }, []);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server');
      if (socket.connected) {
        emitSocket();
        scrollToBottom();
        setIsConnected(true);
      }
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from server');
      setIsConnected(false);
    });

    socket.on('receiveMessage', (message) => {
      setMessages((prevMessages) => [...prevMessages, message]);

      // Messages Read Socket Emit
      // const messageId = message?._id;
      // socket.emit("messageRead", { messageId });
    });

    // return () => {
    //   socket.disconnect();
    //   console.log('Socket disconnected on component unmount');
    // };
  }, []);

  const emitSocket = () => {
    socket.emit('joinRoom', {
      userId: userData?._id,
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const sendMessage = () => {
    if (!messageText.trim()) return;
    socket.emit('sendMessage', {
      message: messageText,
      userId: userData?._id,
      salesId: userData?.contactsales_id?._id,
      sender: 'USER',
    });
    setMessageText('');
  };

  const handleBack = () => {
    socket.disconnect();
    navigate(-1);
  };

  // Handle scroll up event to load more messages
  const handleScroll = () => {
    if (chatContainerRef.current.scrollTop === 0) {
      // User has scrolled to the top, load more messages
      setPage((prevPage) => prevPage + 1);
      getUsers(page + 1);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  // Remove duplicate messages based on message ID
  const filteredMessages = messages?.filter((msg, index, self) => {
    return index === self.findIndex((m) => m._id === msg._id);
  });

  return (
    <div>
      {isLoading && <Loader />}
      <div>
        <div
          className="container-fluid d-flex flex-column"
          style={{height: '83vh'}}>
          {/* Header Code */}
          <div
            className="row p-2"
            style={{
              backgroundColor: isConnected ? '#BC923F' : '#6C757D',
              color: '#fff',
              zIndex: 10,
            }}>
            <div className="col d-flex align-items-center" onClick={handleBack}>
              <div role="button" className="fs-4  pe-3">
                <SvgsComponent name="backBtnWhite" alt="backBtnWhite" />
              </div>
              <h6 className="mb-0">
                {userData?.first_name} -{' '}
                {isConnected ? 'Connected' : 'Disconnected'}
              </h6>
            </div>
          </div>
          {/* Chat Messages Area */}
          <div
            className="row flex-grow-1 overflow-auto p-3 bg-light"
            style={{marginBottom: 60}}
            onScroll={handleScroll}
            ref={chatContainerRef}>
            <div className="col">
              <div className="d-flex flex-column">
                {filteredMessages?.map((msg, index) => (
                  <>
                    <strong
                      className={`position-relative align-self-${
                        msg.sender === 'USER' ? 'end' : 'start'
                      } rounded`}>
                      {msg?.sender == 'USER'
                        ? 'Me'
                        : msg?.sender === 'USER'
                        ? msg?.userId?.first_name || 'Sales'
                        : msg?.salesId?.first_name || 'Admin'}
                    </strong>

                    <div
                      key={index}
                      className={`position-relative align-self-${
                        msg.sender === 'USER' ? 'end me-2' : 'start ms-2'
                      } px-3 py-2 mb-3 shadow-sm`}
                      style={{
                        backgroundColor:
                          msg.sender === 'USER' ? '#BC923F' : '#F1F3F9',
                        color: msg.sender === 'USER' ? '#fff' : '#333',
                        maxWidth: '70%',
                        alignSelf:
                          msg.sender === 'USER' ? 'flex-end' : 'flex-start',
                        borderRadius:
                          msg.sender === 'USER'
                            ? '1rem  0 1rem 1rem '
                            : '0 1rem 1rem  1rem',
                      }}>
                      <div className="p-0">{msg.message}</div>
                      <p
                        className={`mb-0 small`}
                        style={{
                          color: msg?.sender === 'USER' ? '#fff' : '#333',
                          bottom: '5px',
                          right: '10px',
                          fontSize: '0.75rem',
                        }}>
                        {moment(msg.createdAt).format('h:mm A')}
                      </p>
                    </div>
                  </>
                ))}
              </div>
              <div ref={messagesEndRef} />
            </div>
          </div>

          {/* Message Input Area */}
          <div
            className="row bg-white p-3 border-top fixed-bottom-chatInput"
            id="main">
            <div className="col">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type a message..."
                  value={messageText}
                  onChange={(e) => setMessageText(e.target.value)}
                  style={{
                    borderTopLeftRadius: '25px',
                    borderBottomLeftRadius: '25px',
                  }}
                />
                <button
                  disabled={!messageText}
                  className="btn"
                  onClick={() => sendMessage()}
                  style={{
                    backgroundColor: isConnected ? '#BC923F' : '#6C757D',
                    color: '#ffffff',
                    borderTopRightRadius: '25px',
                    borderBottomRightRadius: '25px',
                  }}
                  type="button">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
