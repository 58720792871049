import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translations.json';
import translationZH from './locales/zh/translations.json';
import translationRS from './locales/rs/translations.json';
import translationAR from './locales/ar/translations.json';
import translationHI from './locales/hi/translations.json';
import translationFr from './locales/fr/translations.json';
import translationSp from './locales/sp/translations.json';
import translationIt from './locales/it/translations.json';
import translationGe from './locales/ge/translations.json';
import translationJa from './locales/ja/translations.json';
import translationCh from './locales/ch/translations.json';
import translationTu from './locales/tu/translations.json';
import translationKo from './locales/ko/translations.json';
import translationPe from './locales/pe/translations.json';
import translationPr from './locales/pr/translations.json';

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
  rs: {
    translation: translationRS,
  },
  ar: {
    translation: translationAR,
  },
  hi: {
    translation: translationHI,
  },
  fr: {
    translation: translationFr,
  },
  sp: {
    translation: translationSp,
  },
  it: {
    translation: translationIt,
  },
  ge: {
    translation: translationGe,
  },
  ja: {
    translation: translationJa,
  },
  ch: {
    translation: translationCh,
  },
  tu: {
    translation: translationTu,
  },
  ko: {
    translation: translationKo,
  },
  pe: {
    translation: translationPe,
  },
  pr: {
    translation: translationPr,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en', // fallback language
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['cookie'],
      cookieMinutes: 10,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
